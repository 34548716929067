import WsProvider from './provider/WSProvider'

var token = localStorage.getItem("userDataDemirag");
var serviceName = "webservis";
var moduleName = "urun";


var urunService = {
    imagePath: WsProvider.imagePath,
    imagePathBuyuk: WsProvider.imagePathBuyuk,
    imagePathOrta: WsProvider.imagePathOrta,
    imagePathKucuk: WsProvider.imagePathKucuk,
    belgePath: WsProvider.documentPath,
    path: WsProvider.path,

    urunTanimla(baslik, altBaslik, icerik, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, resim) {
        var param = {
            baslik,
            altBaslik,
            icerik,
            resimKonum,
            menuKonum,
            galeriKonum,
            durum,
            seoBaslik,
            seoIcerik,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,
            seoSayac: 0,
            resim,
            token: token,
            serviceName: moduleName,
            methodName: "urunEkle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    urunTumListesi(durum, baslangic, limit) {
        var param = {
            durum: durum,
            baslangic,
            limit,
            token: token,
            serviceName: moduleName,
            methodName: "urunListele"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    urunDetay(urunID) {
        var param = {
            urunID,
            token: token,
            serviceName: moduleName,
            methodName: "urunDetay"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    urunAktifPasifSayisi() {
        var param = {
            token: token,
            serviceName: moduleName,
            methodName: "urunAktifPasifSayisi"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    urunDurumGuncelle(urunID, durum) {
        var param = {
            urunID,
            durum,
            token: token,
            serviceName: moduleName,
            methodName: "urunDurumDegistir"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    urunGuncelle(urunID, baslik, altBaslik, resim, icerik, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, link) {
        var param = {            
            urunID,            
            baslik, 
            altBaslik,           
            resim,
            icerik,
            resimKonum,
            menuKonum,
            galeriKonum,
            durum,
            seoKategori: 1,
            seoSorguTuru: 1,
            seoDurum: durum,            
            seoSayac: 0,
            seoBaslik,
            seoIcerik,   
            link: link,
            token: token,
            serviceName: moduleName,
            methodName: "urunGuncelle"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    productSearch(arananKelime, durum, baslangic, limit) {               
        var param = {
            arananKelime,
            durum,
            baslangic,
            limit,
            token: token,
            serviceName: moduleName,
            methodName: "urunArama"
        }
        var result = new Promise(function(resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
        return result
    },

    
}

export default urunService